// noinspection JSUnresolvedFunction,JSUnresolvedVariable

/**
 * BE STUDIO
 * BLIND TIGER
 * Web Application
 *
 * HTML Sanitizer Configuration & Function
 *
 * This uses the DOMPurify library (https://github.com/cure53/DOMPurify) as the
 * sanitizer library.
 *
 * @author E. Lew & B. Mockute
 * @copyright (C)2022, BE Studio
 * @see www.its-be-studio.com
 * @version 1.x
 */
function configureSanitizer() {
  // Link URL scheme allow list.
  const allowList = ["https"];
  const regex = RegExp(`^(${allowList.join("|")}):`, "gim");

  DOMPurify.addHook("afterSanitizeAttributes", node => {
    // Ensures only URLs in links conform to the allow list.
    const anchor = document.createElement("a");
    if(node.hasAttribute("href")) {
      anchor.href = node.getAttribute("href");
      if(anchor.protocol && !anchor.protocol.match(regex)) {
        node.removeAttribute("href");
      }
    }
    if(node.hasAttribute("action")) {
      anchor.href = node.getAttribute("action");
      if(anchor.protocol && !anchor.protocol.match(regex)) {
        node.removeAttribute("action");
      }
    }
    if(node.hasAttribute("xlink:href")) {
      anchor.href = node.getAttribute("xlink:href");
      if(anchor.protocol && !anchor.protocol.match(regex)) {
        node.removeAttribute("xlink:href");
      }
    }

    // Forces links in injected HTML to open in a new tab/window.
    if("target" in node) {
      node.setAttribute("target", "_blank");
    }
  });
}


function sanitize(content) {
  return DOMPurify.sanitize(content);
}


configureSanitizer();
